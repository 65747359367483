import React,{useState} from "react";
import {Section,Tabs} from "../../components";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import first from "../../assets/images/Group2.png";
import second from "../../assets/images/Group3.png";
import third from "../../assets/images/Group4.png";
import pc from "../../assets/icons/desktop.svg";
import mobile from "../../assets/icons/mobile.svg"
import { translate } from "../../translations/localization";

const Tab={
    "first":"ЛИЧЕН ГРAФИК",
    "second":"ДОБАВЯНЕ НА ГРУПА",
    "third":"ПРОСЛЕДЯВАНЕ НА СЕСИЯ",
    "fourth":"УПРАВЛЕНИЕ",
    "fifth" : "ЛИЧНО УПРАВЛЕНИЕ",
    "sixth": "ВАШИТЕ ПРОФИЛИ",
    "seventh": "ЛИЧЕН ПРОФИЛ",
    "eigth": "ДЕТАЙЛЕН ГРАФИК",
    "ninth": "ИНДИВИДУАЛЕН ГРАФИК"
}

const Functionalities = () =>{
    const [FunctsTab, setFunctsTab] = useState("Desktop");
    const [TrackSession,setTrackSession] = useState("SecondDesktop");
    const [Management,setManagement] = useState("Management");
    const [Profiles,setProfiles] = useState("Profiles");
    const [Graphic,setGraphic] = useState("1");
    const location = useLocation().pathname;
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;


return(<>{ isMobileVersion === 1 ? <>
  <div className = "mobile-functionalities-container">
        <div className="whole-section black">
        <div className="background-tab"><img src={pc} style={{ width: "10%"}}/></div>
        <h4>{translate("Func11")}</h4>
        <Section 
         image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-deatiled-schedule.png)`} 
         isMobile={isMobileVersion}
         contain
         isWithInvertedTitle={0}
         title={""}
         titleId={"abc"}
         removeLine={1}
         reversed
         moveupinner
         renderContent={() => {return(
           <>
           <p className="not-bold-text">{translate("Func12")}</p>
           <ul>
           <li id="medium-li">{translate("Func13")}</li>
           <li id="medium-li">{translate("Func14")}</li>
         </ul>
         <p>{translate("Func15")}</p>
        </>)
         }}
         /> 

         <div className="background-tab"><img src={mobile}/></div>
         <h4>{translate("Func16")}</h4>
         <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-detailed-schedule.png)`}
            removeLine={1}
            mobile
            reversed
            bigger
            inverted
            title={""}
            moveupinner
            renderContent={()=>{return(
             <>
             <p>{translate("Func17")}</p>
             <p>{translate("Func18")}</p>
             <ul>
              <li className="medium-list-item" id="medium-li">{translate("Func19")}</li>
              <li className="medium-list-item" id="medium-li">{translate("Func20")}</li>
            </ul>
            <p>{translate("Func21")}</p>
             </>)
            }}
          />


        </div>


        <div className="whole-section">
            <div className="background-tab"><img src={pc} style={{ width: "10%"}}/></div>
            <h4>{translate("Func22")}</h4>
            <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalties-track-session-desktop.png)`} 
            isMobile={isMobileVersion}
            isWithInvertedTitle={0}
            titleId={"abc"}
            removeLine={1}
            title={""}
            renderContent={() => {return(
              <>
              <p>{translate("Func23")}</p>
              <ul>
              <li className="medium-list-item">{translate("Func24")}</li>
              <li className="medium-list-item">{translate("Func25")}</li>
              <li className="medium-list-item">{translate("Func26")}</li>
              <li className="medium-list-item">{translate("Func27")}</li>
              </ul>
              </>)
            }}
            />


            <div className="background-tab"><img src={mobile}/></div>
            <h4>{translate("Func22")}</h4>
            <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalties-track-session-mobile.png)`} 
            isMobile={isMobileVersion}
            isWithInvertedTitle={0}
            titleId={"abc"}
            removeLine={1}
            title={""}
            renderContent={() => {return(
              <>
            <p>{translate("Func28")}</p>
            <ul>
            <li className="medium-list-item">{translate("Func29")}</li>
            <li className="medium-list-item">{translate("Func30")}</li>
            <li className="medium-list-item">{translate("Func31")}</li>
            </ul>
            </>)
            }}
            />
        </div>
            
        <div className="whole-section black">
        <div className="background-tab"><img src={pc} style={{ width: "10%"}}/></div>
        <h4>{translate("Func32")}</h4>
        <Section 
         image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-management.png)`} 
         isMobile={isMobileVersion}
         isWithInvertedTitle={0}
         titleId={"abc"}
         removeLine={1}
         reversed
         title={""}
         renderContent={() => {return(
          <>
          <p>{translate("Func33")}</p>
          <ul>
          <li>{translate("Func34")}</li>
          <li>{translate("Func35")} </li>
          <li>{translate("Func36")}</li>
          </ul>
          </>)
         }}
         /> 

         <div className="background-tab"><img src={mobile}/></div>
         <h4>{translate("Func37")}</h4>
         <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-personal-management.png)`}
            removeLine={1}
            mobile
            reversed
            inverted
            contain
            moveupinner
            title={""}
            renderContent={()=>{return(
              <>
              <p>{translate("Func38")}</p>
              
              <ul>
              <li>{translate("Func39")}</li>
              <li>{translate("Func40")} </li>
              </ul>
      
              </>)
            }}
          />


        </div>

        <div className="whole-section">
        <div className="background-tab"><img src={pc} style={{ width: "10%"}}/></div>
        <h4>{translate("Func41")}</h4>
        <Section 
        image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-your-profiles.png)`} 
        isMobile={isMobileVersion}
        isWithInvertedTitle={0}
        titleId={"abc"}
        removeLine={1}
        renderContent={() => {return(
          <>
        <p>{translate("Func42")}</p>
        <ul>
        <li>{translate("Func43")}</li>
        <li>{translate("Func44")}</li>
        <li>{translate("Func45")}</li>
        </ul>
        </>)
        }}
        />


        <div className="background-tab"><img src={mobile}/></div>
        <h4>{translate("Func46")}</h4>
        <Section 
        image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-personal-prifile.png)`} 
        isMobile={isMobileVersion}
        isWithInvertedTitle={0}
        titleId={"abc"}
        removeLine={1}
        contain
        title={""}
        moveupinner
        renderContent={() => {return(
          <>
        <p>{translate("Func47")}</p>
        
        <p>{translate("Func48")}</p>
        </>)
        }}
        />
        </div>

        <div className="whole-section black">
        <div className="background-tab">1</div>
        <h4>{translate("Func11")}</h4>
        <Section 
         image={`url(${first})`} 
         isMobile={isMobileVersion}
         isWithInvertedTitle={0}
         titleId={"abc"}
         removeLine={1}
         reversed
         title={""}
         contain
         renderContent={() => {return(
          <> <p>{translate("Func49")}</p>
              <ul>
                <li>{translate("Func50")}</li>
                <li>{translate("Func50")}</li>
              </ul>   
          </>)
         }}
         /> 

         <div className="background-tab">2</div>
         <h4>{translate("Func16")}</h4>
         <Section 
            image={`url(${second})`} 
            isMobile={isMobileVersion}
            removeLine={1}
            mobile
            reversed
            inverted
            title={""}
            renderContent={()=>{return(
              <> 
                  <p>{translate("Func51")}</p>
                 <p>{translate("Func52")}</p>
                    <ul>
                      <li>{translate("Func53")}</li>
                      <li>{translate("Func54")}</li>
                    </ul> 
                  </>)
            }}
          />

        <div className="background-tab">3</div>
         <h4>{translate("Func22")}</h4>
         <Section 
            image={`url(${third})`} 
            isMobile={isMobileVersion}
            removeLine={1}
            mobile
            reversed
            inverted
            title={""}
            renderContent={()=>{return(
              <> 
                  <p>{translate("Func55")}</p>
                  <ul>
                    <li>{translate("Func56")}</li>
                    <li>{translate("Func57")}</li>
                    <li>{translate("Func58")}</li>
                  </ul>
                  </>)
            }}
          />


        </div>

  </div>
    </> 
    
    
    : 

    
    <div className="functionalities-container">
        <div className="Tabs">
            <Tabs
                wide
                black
                icon={1}
                selected={FunctsTab}
                tabs={[
                  "Desktop",
                  "Mobile",
              ]}
                onSelect={(tab) => setFunctsTab(tab)}
            />
        <Section
        title={FunctsTab === "Desktop" ? translate("Func59") : 
        FunctsTab === "Mobile" ? translate("Func60") : "" }
        image={FunctsTab === "Desktop" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-deatiled-schedule.png)` : 
        FunctsTab === "Mobile" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-detailed-schedule.png)` : ""}
        removeLine={1}
        invert
        smallerImage
        backgroundBlack
        isWithInvertedTitle={1}
        renderContent={() => {
        return (
        <>
        {FunctsTab === "Desktop" ? <>
        <p className="not-bold-text">{translate("Func61")}</p>
        <ul>
        <li id="medium-li">{translate("Func62")}</li>
        <li id="medium-li">{translate("Func63")}</li>
      </ul>
      <p>{translate("Func64")}</p>
        </>  : 
        FunctsTab === "Mobile" ? <>
             <p style={{fontSize:"18px"}}>{translate("Func65")}</p>
             <p style={{fontSize:"18px"}}>{translate("Func66")}</p>
             <ul>
              <li className="medium-list-item" id="medium-li">{translate("Func67")}</li>
              <li className="medium-list-item" id="medium-li">{translate("Func68")}</li>
            </ul>
            <p>{translate("Func69")}</p>
        </>: ""} 

        </>);}}
        />

        </div>

        <div className="Tabs">
        <Section 
        title={translate("Func22")}
        image={TrackSession === "SecondDesktop" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalties-track-session-desktop.png)` : 
        TrackSession === "SecondMobile" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalties-track-session-mobile.png)` : 
        ``} 
        isWithInvertedTitle={1}
        titleId={"abc"}
        smallerImage
        removeLine={1}
        renderContent={() => {return(
        TrackSession === "SecondDesktop"? <>
        <p>{translate("Func23")}</p>
            <ul>
              <li className="bold-list-item">{translate("Func24")}</li>
              <li className="bold-list-item">{translate("Func25")}</li>
              <li className="bold-list-item">{translate("Func26")}</li>
              <li className="bold-list-item">{translate("Func27")}</li>
            </ul>
        </> : TrackSession === "SecondMobile" ? <>
        <p>{translate("Func28")}</p>
        <ul>
        <li className="bold-list-item">{translate("Func29")}</li>
        <li className="bold-list-item">{translate("Func30")}</li>
        <li className="bold-list-item">{translate("Func31")}</li>
        </ul>
        </>: ""
        );}}
        />
        <Tabs
                wide
                icon={1}
                selected={TrackSession}
                right
                tabs={[
                  "SecondDesktop",
                  "SecondMobile",
              ]}
                onSelect={(tab) => setTrackSession(tab)}
            />
        </div>

        <div className="Tabs">
            <Tabs
                wide
                black
                icon={1}
                selected={Management}
                tabs={[
                  "Management",
                  "PersonalManagement",
              ]}
                onSelect={(tab) => setManagement(tab)}
            />
        <Section
        title={Management === "Management" ? translate("Func32") : 
        Management === "PersonalManagement" ? translate("Func37") : "" }
        image={Management === "Management" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-management.png)` : 
        Management === "PersonalManagement" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-personal-management.png)` : ""}
        removeLine={1}
        invert
        smallerImage
        backgroundBlack
        isWithInvertedTitle={1}
        renderContent={() => {
        return (
        <>
        {Management === "Management" ? <>
        <p>{translate("Func33")}</p>
          <ul>
          <li>{translate("Func34")}</li>
          <li>{translate("Func35")}</li>
          <li>{translate("Func36")}</li>
          </ul>
        </>  : 



        Management === "PersonalManagement" ? <>
        <p>{translate("Func38")}</p>
              
              <ul>
              <li>{translate("Func39")}</li>
              <li>{translate("Func40")} </li>
              </ul>

        </> : ""} 

        </>);}}
        />

        </div>
        
        <div className="Tabs">
        <Section 
        title={Profiles === "Profiles" ? translate("Func41") :
              Profiles === "PersonalProfile" ? translate("Func46") : ""}
        image={Profiles === "Profiles" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-your-profiles.png)` : 
        Profiles === "PersonalProfile" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/functionalities-personal-prifile.png)` : 
        ``} 
        isWithInvertedTitle={1}
        titleId={"abc"}
        contain={Profiles === "PersonalProfile" ? 1 : 0}
        removeLine={1}
        renderContent={() => {return(
        Profiles === "Profiles"? <>
        <p>{translate("Func42")}</p>
        <ul>
        <li>{translate("Func43")} </li>
        <li>{translate("Func44")}</li>
        <li>{translate("Func45")}</li>
        </ul>

        </> : Profiles === "PersonalProfile" ? <>
        <p>{translate("Func47")}</p>
        
        <p>{translate("Func48")}</p>
        </>: ""
        );}}
        />
        <Tabs
                wide
                icon={1}
                selected={Profiles}
                tabs={[
                  "Profiles",
                  "PersonalProfile",
              ]}
                onSelect={(tab) => setProfiles(tab)}
            />
        </div>

        <div className="Tabs">
                  <Tabs
                        black
                        wide
                        selected={Graphic}
                        tabs={[
                                "1",
                                "2",
                                "3"
                            ]}
                        onSelect={(tab) => setGraphic(tab)}
                    />
            <Section
            title={Graphic === "1" ? translate("Func11") : 
                Graphic === "2" ? translate("Func16") :
                Graphic === "3" ? translate("Func22") : "" }
            image={Graphic === "1" ? `url(${first})` : 
            Graphic === "2" ? `url(${second})` :
            Graphic === "3" ? `url(${third})` : ""}
            invert
            removeLine={1}
            isWithInvertedTitle={1}
            smallerImage
            backgroundBlack
            renderContent={() => {
              return (
                <>
                  {Graphic === "1" ? <> <p>{translate("Func49")} </p>
                  <ul>
                    <li className="mobile-li">{translate("Func50")}</li>
                    <li className="mobile-li">{translate("Func50")}</li>
                  </ul>    
                  </>: 
                  Graphic === "2" ? <> 
                  <p>{translate("Func51")}</p>
                  <p>{translate("Func52")} </p>
                     <ul>
                       <li className="mobile-li">{translate("Func53")}</li>
                       <li className="mobile-li">{translate("Func54")}</li>
                     </ul>   
                  </> :
                  Graphic === "3" ? <> 
                  <p>{translate("Func55")}</p>
                  <ul>
                    <li className="mobile-li">{translate("Func56")} </li>
                     <li className="mobile-li">{translate("Func57")} </li>
                    <li className="mobile-li">{translate("Func58")}</li>
                  </ul>
                  </> : ""} 
                
                </>
              );
            }}
          />
          </div>

    </div>
    }</>
);
}

export default Functionalities;