import React from "react";
import { TitleComponent } from ".././../components";
import "./styles.scss";

const Section = ({
  renderContent,
  invert,
  title,
  image,
  specialImage,
  selected,
  onSelectImage,
  reversed,
  removeLine,
  titleId,
  contain,
  backgroundBlack,
  smallerImage,
  mobile,
  bigger,
  smaller,
  moveupinner,
  muchsmallerImage,
  height
}) => {
  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }


  return (
    <div className={`section-container ${invert && "inverted"}  ${backgroundBlack && "backgroundBlack"} ${ mobile && "mobile" } ${ bigger && "bigger" }`} 
    style={{height:`${height}vh`}} >
      {specialImage ? (
        <div className="container special">
          <div
            className={`image ${selected === "LR" && "selected"}`}
            style={{ backgroundImage: image }}
            onClick={() => onSelectImage("LR")}
          />
          <div
            className={`image ${selected === "IHM" && "selected"} smaller`}
            style={{ backgroundImage: specialImage }}
            onClick={() => onSelectImage("IHM")}
          />
        </div>
      ) : (
        <div className={`container image ${contain && 'contain'}  ${smallerImage && 'smaller'} ${muchsmallerImage && "muchsmaller"}`} style={{ backgroundImage: image }} />
      )}
     <div className={`container content ${color} ${moveupinner && 'moveupinner'}`}>
      <div className={`container-inner ${moveupinner && 'moveupinner'}`}>
      {title==="" ? "" : <TitleComponent title={title} removeLine={removeLine} titleId={titleId} smallerTitle={smaller}/>}
      {renderContent && renderContent()}
      </div>
    </div> 
      
    </div>
   );
};

export default Section;
