import React,{useState} from "react";
import {Section,Tabs,SectionForMap,ContactComponent,Mapforgoogle} from "../../components";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import first from "../../assets/images/homepage-hero-section.png";
import mapche from "../../assets/icons/map.svg";
import contactform from "../../assets/icons/contact-form-icon-white.svg";
import { translate } from "../../translations/localization";

const Tab={
    "first":"ГРАФИК И ПРОСЛЕДЯВАНЕ",
    "second":"ЦЯЛОСТНО УПРАВЛЕНИЕ",
    "third":"МОБИЛНО ПРИЛОЖЕНИЕ"
}

const MapContactTitles={
  "Map": "ГРАФИК И ПРОСЛЕЯВАНЕ",
  "ContactForm" : "Tuk trqbva drugo da stoi!"
}

const Home = () =>{
    const [mainFuncsTab, setMainFuncsTab] = useState("1");
    const [GraphicsTab, setGraphicsTab] = useState("Map");
    const [selectedPartner, setSelectedPartner] = useState("LR");
    const location = useLocation().pathname;
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

    function GetText()
    {
    return (
        <div className="button-n-text">
        <p className="first-section-inner-header">{translate("Home12")}</p>
          <div className="vertical-tab-buttons">
              <div className="line-and-text">
              {isMobileVersion === 1 ? <div className="left-line-mobile"/> : <div className="left-line"/>}
                
                <p className="bold-tab-text"> {translate("Home13")}</p>
              </div>

            <div className="line-and-text">
            {isMobileVersion === 1 ? <div className="left-line-mobile"/> : <div className="left-line"/>}
            <p className="bold-tab-text"> 
            {translate("Home14")}</p>
             </div>

             <div className="line-and-text">
             {isMobileVersion === 1 ? <div className="left-line-mobile"/> : <div className="left-line"/>}
                <p className="bold-tab-text"> 
                {translate("Home15")}</p>
             </div>

             <div className="line-and-text">
             {isMobileVersion === 1 ? <div className="left-line-mobile"/> : <div className="left-line"/>}
             <p className="bold-tab-text"> 
             {translate("Home16")}</p>
            </div>

            <div className="line-and-text firstSection">
            {isMobileVersion === 1 ? <div className="left-line-mobile"/> : <div className="left-line"/>}
             <p className="bold-tab-text"> 
             {translate("Home17")}</p>
             </div>
          
          </div>
          {isMobileVersion === 1 ?  <div className="button-container mobilesection"  onClick={() => {
            window.scrollTo(10,10)
            document.body.style.overflow = "hidden";
            document.getElementById("log-into-system").style.display="block";
          }}>{translate("free-start")}</div> :
            <div className="button-container" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("free-start")}</div>}
        </div>
        )
    }

    function YouChooseText(){
      return (
      <div className="button-n-text">
        <div className="vertical-tab-buttons">
            <div className="line-and-text">
              <div className="left-line"/>
              <p className="medium-tab-text"> {translate("Home43")} </p>
            </div>

          <div className="line-and-text">
          <div className="left-line"/> 
          <p className="medium-tab-text"> 
          {translate("Home44")}</p>
           </div>

           <div className="line-and-text">
           <div className="left-line"/> 
           <p className="medium-tab-text"> 
           {translate("Home45")}</p>
          </div>
        
        </div>
      </div>
      )
    }

    return(
        <div className={`home-container`}>
        <Section 
         title={translate("Home11")}
         image={`url(${first})`} 
         isMobile={isMobileVersion}
         isWithInvertedTitle={0}
         titleId={"firstHome"}
         removeLine={1}
         renderContent={GetText}
         />

         {isMobileVersion === 1  ? 
          <div className="whole-section black"> 
         <div className="number1">1</div>
         <h4>{translate("tab1")}</h4>
         <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-Schedulex.png)`}
            removeLine={1}
            mobile
            reversed
            isMobile={isMobileVersion}
            title={translate("Home21")}
            titleId={"firstfromwhole"}
            smaller={1}
            height={110}
            renderContent={()=>{return(
              <>
                        <div className="button-n-text">
                  <div className="vertical-tab-buttons no-border">
                      <div className="line-and-text">
                        <div className="left-line"/>
                        <p className="not-bold-tab-text">{translate("Home22")} </p>
                      </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home23")}</p>
                    </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home24")}</p>
                    </div>
                    </div>
                    </div>
              </>)
            }}
          />

        <div className="number2">2</div>
         <h4>{translate("tab2")}</h4>
         <Section 
            image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-management.png)`}
            removeLine={1}
            mobile
            reversed
            title={translate("Home29")}
            smaller={1}
            height={110}
            renderContent={()=>{return(
              <>
              <div className="button-n-text">
                  <div className="vertical-tab-buttons no-border">
                      <div className="line-and-text">
                        <div className="left-line"/>
                        <p className="not-bold-tab-text">{translate("Home25")} </p>
                      </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home26")}</p>
                    </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home27")}</p>
                    </div>
                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home28")}</p>
                    </div>
                    </div>
                    </div>
              </>)
            }}
          />

          <div className="number3">3</div>
          <h4>{translate("tab3")}</h4>
          <Section 
             image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-mobile.png)`}
             removeLine={1}
             mobile
             reversed
             title={translate("Home30")}
             smaller={1}
             height={120}
             renderContent={()=>{return(
               <>
               <div className="button-n-text">
                  <div className="vertical-tab-buttons no-border">
                      <div className="line-and-text">
                        <div className="left-line"/>
                        <p className="not-bold-tab-text">{translate("Home31")}</p>
                      </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home32")}</p>
                    </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home33")}</p>
                    </div>
                    <div className="button-container mobilesection"  onClick={() => {
                      window.scrollTo(10,10)
                      document.body.style.overflow = "hidden";
                      document.getElementById("log-into-system").style.display="block";
                    }}>{translate("free-start")}</div>
                    <div className="button-container mobilesection black border" onClick={() => history.push("/how")}>{translate("navHow")}</div>
                    </div>
                    </div>
               </>)
             }}
           />     
         </div>
         :
         <div className="Tabs">
              <Tabs
                    black
                    wide
                    selected={mainFuncsTab}
                    tabs={[
                            "1",
                            "2",
                            "3"
                         ]}
                    onSelect={(tab) => setMainFuncsTab(tab)}
                />
         <Section
         title={mainFuncsTab === "1" ? translate("tab1") : 
            mainFuncsTab === "2" ? translate("tab2") :
            mainFuncsTab === "3" ? translate("tab3") : "" }
         image={mainFuncsTab === "1" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-Schedulex.png)` : 
         mainFuncsTab === "2" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-management.png)` :
         mainFuncsTab === "3" ? `url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-mobile.png)` : ""}
         invert
         removeLine={1}
         isWithInvertedTitle={1}
         smallerImage
         backgroundBlack
         titleId={"firstHome"}
         renderContent={() => {
           return (
             <>
               {mainFuncsTab === "1" ? <> <div className="button-n-text">
               <p className="first-section-inner-header">{translate("Home21")}</p>
            <div className="vertical-tab-buttons no-border">
             <div className="line-and-text">
               <div className="left-line"/>
               <p className="not-bold-tab-text">{translate("Home22")} </p>
             </div>

           <div className="line-and-text">
           <div className="left-line"/> 
           <p className="not-bold-tab-text"> 
           {translate("Home23")}</p>
           </div>

           <div className="line-and-text">
           <div className="left-line"/> 
           <p className="not-bold-tab-text"> 
           {translate("Home24")}</p>
           </div>
           </div>
           <div className="button-container" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("free-start")}</div>
           <div className="button-container black border left" onClick={() => history.push("/how")}>{translate("navHow")}</div>
           </div>  
               </>: 
               mainFuncsTab === "2" ? <> 
               <div className="button-n-text">
              <p className="first-section-inner-header">{translate("Home29")}</p>
                  <div className="vertical-tab-buttons no-border">
                      <div className="line-and-text">
                        <div className="left-line"/>
                        <p className="not-bold-tab-text">{translate("Home25")} </p>
                      </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home26")}</p>
                    </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home27")}</p>
                    </div>
                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home28")}</p>
                    </div>
                    </div>
                    </div>  
                    <div className="button-container" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("free-start")}</div>
               <div className="button-container black border left" onClick={() => history.push("/how")}>{translate("navHow")}</div>
               </> :
               mainFuncsTab === "3" ? <> 
               <div className="button-n-text">
               <p className="first-section-inner-header">{translate("Home30")}</p>
                  <div className="vertical-tab-buttons no-border">
                      <div className="line-and-text">
                        <div className="left-line"/>
                        <p className="not-bold-tab-text">{translate("Home31")}</p>
                      </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home32")}</p>
                    </div>

                    <div className="line-and-text">
                    <div className="left-line"/> 
                    <p className="not-bold-tab-text"> 
                    {translate("Home33")}</p>
                    </div>
                   
                    </div>
                    </div>  
               <div className="button-container" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("free-start")}</div>
               <div className="button-container black border left" onClick={() => history.push("/how")}>{translate("navHow")}</div>
               </> : ""} 
             
            </>
           );
         }}
       />
       </div>
}
    
  
         <div className="ad">
            <div className={`smaller-line ${isMobileVersion === 1 ? "mobile" : ""}`}>
                <div className={`logoforad ${isMobileVersion === 1 ? "mobile" : ""}`}/>
                {isMobileVersion === 1 ? <h4 id="textforad">{translate("Home41")}</h4> : 
                
                <h2 id="textforad">{translate("Home41")}</h2>
                }
                {isMobileVersion === 1 ? <div className="button-container mobilesection downwards"  onClick={() => {
                  window.scrollTo(10,10)
                  document.body.style.overflow = "hidden";
                  document.getElementById("log-into-system").style.display="block";
                }}>{translate("free-start")}</div> : 
                <div className="button-container middle" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("free-start")}</div>}
                
            </div>
         </div>

         {isMobileVersion === 1 ? <>
          <Section 
         title={translate("Home42")}
         image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-you-choose.png)`} 
         isWithInvertedTitle={1}
         titleId={"abc"}
         removeLine={1}
         bigger
         renderContent={YouChooseText}
         />
          </> : 
         
          <Section 
         title={translate("Home42")}
         image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/homepage-you-choose.png)`} 
         isWithInvertedTitle={1}
         titleId={"firstHome"}
         removeLine={1}
         renderContent={YouChooseText}
         />
        }
         


         {isMobileVersion === 1 ? <div className="whole-section">
         <div className="number1 white"><img src={mapche}/></div>
         <h4>{translate("Map")}</h4>
          <>
           <p>{translate("AdditionalInfo")}</p>
          <p><b className="mobile">{translate("Email")}</b><br/>team@fidweb.net</p>
          <p><b className="mobile">{translate("Phone")}</b><br/>+359 2963 1330</p>
          <br/>
          <p className="mobile-address"><b className="mobile">{translate("Address")}</b><br/>София, ул. „Иван Абаджиев“ 2, сграда А, етаж 4</p>
          </>
          <Mapforgoogle 
        center={{   
          lat: 42.6626879801289, 
          lng: 23.38031102658445
         }}

         mobile={1}/>
         <div className="Mobile-Tabs-Contact">
         <div className="number1 white"><img src={contactform} /></div>
         <ContactComponent mobile={1} smallerTitle={1}/>
       </div>
         </div>

           
         
         :
         <div className="Tabs">
         <Tabs
               wide
               icon={1}
               selected={GraphicsTab}
               tabs={[
                       "Map",
                       "ContactForm",
                    ]}
               onSelect={(tab) => setGraphicsTab(tab)}
           />
           
           {GraphicsTab === "Map" ?  
           <SectionForMap
        title={translate("Home46")}
        removeLine={1}
        reversed
        invert
        isWithInvertedTitle={1}
        renderContent={() => {
          return (
            <>
              {GraphicsTab === "Map" ? <>
              <p className="move-left">{translate("AdditionalInfo")}</p>
              <div className="Map-Info">
              <p className="move-left"><b>{translate("Email")}</b><br/>team@fidweb.net</p>
              <p className="move-left"><b>{translate("Phone")}</b><br/>+359 2963 1330</p>
              </div>
              <br/>
              <p className="move-left"><b>{translate("Address")}</b><br/>София, ул. „Иван Абаджиев“ 2, сграда А, етаж 4</p>
              
              </>
              : 
              GraphicsTab === "ContactForm" ? <ContactComponent home/> : ""
              } 
            
          </>
          );
          }}
        />
        : <ContactComponent home/>}
        
  </div>
  }
         </div>
    );
}
export default Home;