import React, { useState, useEffect } from "react";
import { TitleComponent, Button } from "../../components";
import "./styles.scss";
import Terms from "../../assets/files/Terms.pdf";
import Privacy from "../../assets/files/digidrive_privacy.pdf";
import { translate } from "../../translations/localization";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactComponent({home,mobile,smallerTitle,sendEmail}) {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Моля изчакайте няколко секунди."
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Моля изчакайте няколко секунди.....") {
          newLoadingMessage = "Моля изчакайте няколко секунди.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);


   function validateEmail () {
     const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
     if(regex.test(email) === false){
       return false;
     }
     return true;
   }

   function validatePhone(){
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(phoneno.test(phone) === false)return false;
    return true;
   }

  return (<>
    
    {mobile === 1 ? 
      <div className={`contact-component-container ${home && "home"}`}>
    <div className="right-container">
      <div className="inner-container">
        <TitleComponent title={translate("Connect")} removeLine={1} smallerTitle={1}/>
        <br/>
        <div className="flex-container">
          <div className="form-input-wrapper">
            <input
              required
              className="form-input"
              type="text"
              value={fullName}
              placeholder={translate("Name")}
              onChange={(event) => setFullName(event.target.value)}
            />
          </div>
          <div className="form-input-wrapper">
            <input
            required
              className="form-input"
              type="text"
              value={company}
              placeholder={translate("Company")}
              onChange={(event) => setCompany(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container">
          <div className="form-input-wrapper">
            <input
            required
              className="form-input"
              type="text"
              value={email}
              placeholder={translate("Mail")}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form-input-wrapper">
            <input
              required
              className="form-input"
              type="text"
              value={phone}
              placeholder={translate("phoneNumber")}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
        </div>
        <div className="flex-container">
          <div className="form-textarea-wrapper">
            <textarea
              required
              value={message}
              placeholder={translate("Message")}
              onChange={(event) => setMessage(event.target.value)}
            />
          </div>
        </div>
      <div className="flex-end">
        
        <div className="checkboxes"> 
          <div className="first-checkbox">
          <input type="checkbox" id="accept1"/>
            <span className="checkmark"></span>
            <label className="container" onClick={() => window.open(Terms)}><u>{translate("Usage")}</u> 
            </label>
          </div>
            <div className="second-checkbox">
            <input type="checkbox" id="accept2"/>
              <span class="checkmark"></span>
              <label class="container" onClick={() => {window.open(Privacy)}}><u>{translate("Privacy")}</u>
              </label>
            </div>
        </div>

        <div className="button-container mobilee" onClick={() => {

          const firstcheck = document.getElementById("accept1").checked;
          const secondcheck = document.getElementById("accept2").checked;
          if (!loading && validateEmail() && firstcheck && secondcheck) {
            document.getElementById("error").style.display="none";
            setLoading(true);
            setSuccess(false);
            const payload = {
              system: "digiDrive",
              sender: fullName,
              company: company,
              email: email,
              phoneNumber: phone,
              message: message,
              onSuccess: () => {
                setLoading(false);
                setSuccess(true);
                setFullName("");
                setCompany("");
                setEmail("");
                setPhone("");
                setMessage("");
              },
              onError: (error) => {
                setLoading(false);
                toast(error);
              },
            };
            sendEmail(payload);
          }
          else document.getElementById("error").style.display="block";
        }}>ИЗПРАТИ</div>
      </div>
        <p style={{display:"none"}} id="error">{translate("error1")}</p>           
        {loading && <p>{loadingMessage}</p>}
        {success && <p>{translate("right")}</p>}
        </div>
    </div>
    <div className="left-container" />
      </div> 
  
  :  
    <div className={`contact-component-container ${home && "home"}`}>
      <div className="left-container" />
      <div className="right-container">
        <div className="inner-container">
          <TitleComponent title={translate("Connect")} removeLine={1}/>
          <br/>
          <div className="flex-container" style={{marginTop:"16%"}}>
            <div className="form-input-wrapper">
              <input
                required
                className="form-input"
                type="text"
                value={fullName}
                placeholder={translate("Name")}
                onChange={(event) => setFullName(event.target.value)}
              />
            </div>
            <div className="form-input-wrapper">
              <input
              required
                className="form-input"
                type="text"
                value={company}
                placeholder={translate("Company")}
                onChange={(event) => setCompany(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="form-input-wrapper">
              <input
              required
                className="form-input"
                type="text"
                value={email}
                placeholder={translate("Mail")}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="form-input-wrapper">
              <input
                required
                className="form-input"
                type="text"
                value={phone}
                placeholder={translate("phoneNumber")}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="form-textarea-wrapper">
              <textarea
                required
                value={message}
                placeholder={translate("Message")}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
          </div>
        <div className="flex-end">
          
          <div className="checkboxes"> 
            <div className="first-checkbox">
              <input type="checkbox" id="accept1"/>
              <span className="checkmark"></span>
              <label className="container" onClick={() => window.open(Terms)}><u>{translate("Usage")}</u>   
              </label>
            </div>
              <div className="second-checkbox">
                <input type="checkbox" id="accept2"/>
                <span class="checkmark"></span>
                <label class="container" onClick={() => {window.open(Privacy)}}><u>{translate("Privacy")}</u>
                </label>
              </div>
          </div>


          <div className="button-container" onClick={() => {

            const firstcheck = document.getElementById("accept1").checked;
            const secondcheck = document.getElementById("accept2").checked;
            if (!loading && validateEmail() && firstcheck && secondcheck) {
              document.getElementById("error").style.display="none";
              setLoading(true);
              setSuccess(false);
              const payload = {
                system: "digiDrive",
                sender: fullName,
                company: company,
                email: email,
                phoneNumber: phone,
                message: message,
                onSuccess: () => {
                  setLoading(false);
                  setSuccess(true);
                  setFullName("");
                  setCompany("");
                  setEmail("");
                  setPhone("");
                  setMessage("");
                },
                onError: (error) => {
                  setLoading(false);
                  toast(error);
                },
              };
              sendEmail(payload);
            }
            else document.getElementById("error").style.display="block";
          }}>{translate("Send")}</div>
        </div>
          <p style={{display:"none"}} id="error">{translate("error1")}</p>           
          {loading && <p>{loadingMessage}</p>}
          {success && <p>{translate("right")}</p>}
        </div>
      </div>
    </div>
            }
      </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null,mapDispatchToProps)(ContactComponent);
