import React from "react";
import "./styles.scss";

function TitleComponent({ title, small, mobileSpecial,removeLine,titleId,smallerTitle }) {

  return (
    <div
      className={`title-container ${mobileSpecial && "mobile-special"} ${ small && "small" }` }
      
    >
    {smallerTitle === 1 ? <h3 id={`${titleId}`}>{title}</h3> : 
    <h1 id={`${titleId}`}>{title}</h1>}

      {removeLine === 1 ?  "" : <div className="line"/>}
    </div>
  );
}

export default TitleComponent;
