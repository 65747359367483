import React from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import {Header,Footer,MobileHeader,MobileFooter} from "../../components";
import {Home} from "../../screens/Home";
import {Functionalities} from "../../screens/Functionalities";
import {About} from "../../screens/About";
import {HowItWorks} from "../../screens/HowItWorks";
import { Article } from "../../screens/Article";

const Routes = () => {
  return (
    <div className="dashboard-wrap">
      <Header />
      <MobileHeader/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/functionalities" component={Functionalities} />
        <Route exact path="/about" component={About} />
        <Route exact path="/how" component={HowItWorks} />
        <Route exact path="/article" component={Article} />
      </Switch>
      <Footer/>
    </div>
  );
};

export default withRouter(Routes);
