import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import data from "../../screens/ArticleInfo/ArticleData.json"
import images from "../../screens/ArticleInfo/ArticleImages.json"

const Article = () =>{
    const location = useLocation();
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    const index = history.location.search.match(/\d/g)[0];
    const result = data[Object.keys(data)[index]].split("n");
    result.shift();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(<>
        {isMobileVersion === 1 ? <>
            <div className="mobile-article-container">
            <div className="mobile-arrow-left" onClick={() => history.push("/about")}/>
            <h3>{Object.keys(data)[index]}</h3>
            <div className="mobile-right-article-container" style={{backgroundImage:`url(${images[Object.keys(images)[index]]})`}}/>
            <div className="mobile-neshto">
            {result.map((sentence,index)=>{
                if(sentence[1] === "“") return(<div className="mobile-article-text italic">{sentence}</div>)

                if(sentence[1] === "Д" && sentence[1] === "З") return(<div className="mobile-article-text"><b>{sentence}</b></div>)
                
                return(<div className="mobile-article-text">{sentence}</div>)
            })}
            </div>
            </div>
            </> 
        
        
        
            :
            <div className="article-container">
                <div className="left-article-container">
                <div className="arrow-left" onClick={() => history.push("/about")}/>
                    <h2>{Object.keys(data)[index]}</h2>
                    <div className="neshto">
                    {result.map((sentence,index)=>{
                        if(sentence[1] === "“") return(<div className="article-text italic">{sentence}</div>)

                        if(sentence[1] === "Д" && sentence[1] === "З") return(<div className="article-text"><b>{sentence}</b></div>)
                        
                        return(<div className="article-text">{sentence}</div>)
                    })}
                    
                    </div>
                </div>
                <div className="right-article-container" style={{backgroundImage:`url(${images[Object.keys(images)[index]]})`}}>
                </div>
            </div>
            }

    </>);
}

export default Article;