import {React,useState,useEffect} from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { setLocale, translate } from "../../translations/localization";
import "./styles.scss";

const Header = () => {
  const location = useLocation().pathname;
  const [language, setLanguage] = useState("");
  const languageCode = localStorage.getItem("immoLanguage");

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else {
      setLanguage(languageCode);
    }
  }, [languageCode]);

  return (
    <div className="header-container">
        <div className="left-header-container">
        <div className="header-logo" onClick={() => history.push("/")} />
        </div>

        <div className="right-header-container">
        <div className="header-element" onClick={() => history.push("/functionalities")}>
            <div className={`header-inner-element ${location === "/functionalities" && "selected"}`}>
            {translate("navFunc")}
            </div>
        </div>
        <div className="header-element" onClick={() => history.push("/how")}>
          <div className={`header-inner-element ${location === "/how" && "selected"}`}>
          {translate("navHow")}
          </div>
        </div>
        <div className="header-element" onClick={() => history.push("/about")}>
          <div className={`header-inner-element ${location === "/about" ? "selected" : location === "/article" ? "selected" : ""}`}>
          {translate("navAbout")}
          </div>
        </div>
        <div className="header-element button" onClick={() => window.open("https://web.digi-drive.app/login")}> {translate("navLogin")} </div>
        <div className="languages-container" style={{display:"flex"}}>
        <div
          className={`language border ${language === "bg" && "selected"}`}
          onClick={() => {
            setLanguage("bg");
            setLocale("bg");
            localStorage.setItem("immoLanguage", "bg");
            history.push(location.pathname);
          }}
        >
          BG
        </div>
        <div className="seperator" />
        <div
          className={`language ${language === "de" && "selected"}`}
          onClick={() => {
            setLanguage("de");
            setLocale("de");
            localStorage.setItem("immoLanguage", "de");
            history.push(location.pathname);
          }}
        >
          DE
        </div>
      </div>
        </div>
    </div>
  );
};

export default Header;
