import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import { Icon } from "react-icons-kit";
import {menu} from 'react-icons-kit/entypo/menu'
import { close } from "react-icons-kit/fa/close";
import { setLocale, translate } from "../../translations/localization";

const MobileHeader = () => {
  const location = useLocation().pathname;
  const [language, setLanguage] = useState("");
  const languageCode = localStorage.getItem("immoLanguage");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!languageCode) {
      localStorage.setItem("immoLanguage", "bg");
      setLanguage("bg");
      setLocale("bg");
    } else {
      setLanguage(languageCode);
    }
  }, [languageCode]);

  return (
    <div className="mobile-container">
      <div className="log-into-system" id="log-into-system">
      <div className="close-icon" onClick={()=>{
        setOpen(false);
        document.getElementById("log-into-system").style.display="none";
        document.body.style.overflow = "visible";
      }}>
        <div className="x-icon">
        </div>
      </div>
      <h3 className="log">{translate("navLogin")}</h3>
      <div className="log-text">
        <p>{translate("InnerHeader1")}</p>
        <p>{translate("InnerHeader2")}</p>
      </div>
      
    </div>

      <div
        className="button"
        onClick={() => {
          setOpen(true);
          document.body.style.overflow = "hidden";
        }}
      >
        <Icon icon={menu}  style={{ color: "#0BD8DE" }} size={40} />
      </div>
      <div className="mobile-header-logo" onClick={() => history.push("/")} />
      {open && (
        <div className="opened-header">
          <div className="upper-opened-header">
            <div
              className="close-opened-header"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
              }}
            >
              <Icon icon={close} style={{ color: "#0BD8DE" }} size={32} />
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/" && "selected"
                }`}
              >
                {translate("navHome")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/functionalities");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/functionalities" && "selected"
                }`}
              >
                {translate("navFunc")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/how");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/how" && "selected"
                }`}
              >
                {translate("navHow")}
              </div>
            </div>
            <div
              className="mobile-header-element"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "visible";
                history.push("/about");
              }}
            >
              <div
                className={`mobile-header-inner-element ${
                  location === "/about" ? "selected" : location === "/article" ? "selected" : ""
                }`}
              >
                {translate("navAbout")}
              </div>
            </div>
            <div
              className="button-mobile"
              onClick={() => {
                setOpen(false);
                document.body.style.overflow = "hidden";
                document.getElementById("log-into-system").style.display="block";
              }}
            >
            {translate("navLogin")} 
            </div>
            <div className="mobile-flex-container-apps">
            <div
              className="app-store white"
              onClick={() => window.open("https://apps.apple.com/bg/app/digidrive/id1482770290")}
            />
            <div
              className="google-play white"
               onClick={() =>
                     window.open("https://play.google.com/store/apps/details?id=com.socourt.digidrive")
                    }
            />
          </div>
          <div className="languages-flex-container" style={{display:"flex"}}>
          <div
                className={`language-button ${language === "bg" && "selected"}`}
                onClick={() => {
                  setLanguage("bg");
                  setLocale("bg");
                  localStorage.setItem("immoLanguage", "bg");
                  history.push(location.pathname);
                }}
                style={{marginRight:"10%"}}
              >
                BG
              </div>
              <div
                className={`language-button margin ${
                  language === "de" && "selected"
                }`}
                onClick={() => {
                  setLanguage("de");
                  setLocale("de");
                  localStorage.setItem("immoLanguage", "de");
                  history.push(location.pathname);
                }}
              >
                DE
              </div>
            </div>
          </div>
          <div className="lower-opened-header"></div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;