import React from "react";
import { TitleComponent,Mapforgoogle } from ".././../components";
import "./styles.scss";

const SectionForMap = ({
  renderContent,
  invert,
  title,
  image,
  specialImage,
  selected,
  onSelectImage,
  reversed,
  isMobile,
  removeLine,
  isWithInvertedTitle,
  isWithInvertedTitleandText,
  titleId,
  contain,
  backgroundBlack,
  smallerImage,
  howit
}) => {
  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }


  return (
    <div className={`section-container ${invert && "inverted"}  ${backgroundBlack && "backgroundBlack"}  ${howit && "howit"}`} >
      {specialImage ? (
        <div className="container special">
          <div
            className={`image ${selected === "LR" && "selected"}`}
            style={{ backgroundImage: image }}
            onClick={() => onSelectImage("LR")}
          />
          <div
            className={`image ${selected === "IHM" && "selected"} smaller`}
            style={{ backgroundImage: specialImage }}
            onClick={() => onSelectImage("IHM")}
          />
        </div>
      ) : (
        <Mapforgoogle 
        center={{   
          lat: 42.6626879801289, 
          lng: 23.38031102658445
         }}
         
      
      />
      )}
     <div className={`container content ${color}`}>
      <div className="container-inner">
        <TitleComponent title={title} removeLine={removeLine} titleId={titleId}/>
      {renderContent && renderContent()}
      </div>
    </div> 
      
    </div>
   );
};

export default SectionForMap;
