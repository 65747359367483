import React from "react";
import {Section} from "../../components";
import "./styles.scss";
import NewsComponent from "../../components/NewsComponent/NewsComponent";
import { translate } from "../../translations/localization";

const About = () =>{
    return(
        <div className="about-container">
        <Section
    title={translate("navAbout")}
    image={`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/about-us-photo.png)`}
    removeLine={1}
    reversed
    backgroundBlack
    isWithInvertedTitle={1}
    renderContent={() => {
    return ( 
        <ul>
        <li style={{marginLeft:"-2%"}}><b>{translate("About1")}</b></li>
        <li style={{marginLeft:"-2%"}}><b>{translate("About2")}</b></li>
        <li style={{marginLeft:"-2%"}}><b>{translate("About3")}</b></li>
       </ul>
     
        );
    }}
    />
    <NewsComponent/>
        </div>
    )
}

export default About;