import React from "react";
import "./styles.scss";
import { history } from "../../config/stores";
import { useLocation } from "react-router-dom";
import {Button} from "../../components";
import Terms from "../../assets/files/Terms.pdf";
import Privacy from "../../assets/files/digidrive_privacy.pdf";
import { translate } from "../../translations/localization";


const Footer = () => {
  const location = useLocation().pathname;
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;


  return (<>
    {isMobileVersion === 1 ? <>
      <div className="mobile-full-footer-container">
        <div className="mobile-footer-element">
          <div className="mobile-footer-logo"/>
          <div className="button-container mobilesection footer"  onClick={() => {
            window.scrollTo(10,10)
            document.body.style.overflow = "hidden";
            document.getElementById("log-into-system").style.display="block";
          }}>{translate("free-start")}</div>
        </div> 
        <div className="mobile-footer-element">
              <div className="mobile-footer-inner-element">
                <p><b>{translate("Download")}</b></p>
                <div className="mobile-flex-container">
                  <div
                    className="mobile-app-store white"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/bg/app/digidrive/id1482770290"
                      )
                    }
                  />
                  <div
                    className="mobile-google-play white"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.socourt.digidrive"
                      )
                    }
                  />
                </div>
              </div>
        </div>   
        <div className="mobile-footer-element">
            <p id="mobile-bigger-info">{translate("Connect")}</p>
            <div className="mobile-contact-container">
              <p id="mobile-contact-info"><b>E:</b> team@fidweb.net</p>
              <p id="mobile-contact-info"><b>T:</b> +359 2963 1330</p>
              <p id="mobile-contact-info"><b>A:</b> София, ул. ,,Иван Абаджиев'' 2, сграда А, етаж 4</p>
            </div>
        </div>
        <div className="mobile-footer-element">
                <p id="mobile-bigger-info">{translate("Connect")}</p>
                <div className="mobile-all-logos">
                  <div className="mobile-footer-social-media-logo facebook" onClick={() => window.open("https://www.facebook.com/fidwebnet/")}/>
                  <div className="mobile-footer-social-media-logo instagram" onClick={() => window.open("https://www.instagram.com/fidweb/")}/>
                </div>
        </div>
        <br/>
        <div className="mobile-footer-element last">
          <p id="bigger" onClick={() => window.open(Privacy)}>{translate("Privacy")}</p>
          <p id="bigger" onClick={() => window.open(Terms)}>{translate("Usage")}</p>
          </div>
      </div>
      </>
      :
        <div className="full-footer-container">
          <div className="upper-footer-container">
            <div className="footer-element">
              <div className="footer-inner-element logo">
              <div className="footer-logo"/>
              <div className="button-container" onClick={() => window.open("https://web.digi-drive.app/login")}>{translate("navLogin")}</div>
              
              </div>
            </div>
            <div className="footer-element">
              <div className="footer-inner-element">
                <p><b>{translate("Download")}</b></p>
                <div className="flex-container">
                  <div
                    className="app-store white"
                    onClick={() => window.open("https://apps.apple.com/bg/app/digidrive/id1482770290")
                    }
                  />
                  <div
                    className="google-play white"
                    onClick={() =>
                     window.open("https://play.google.com/store/apps/details?id=com.socourt.digidrive")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="footer-element">
              <div className="footer-inner-element contact">
                <p id="bigger-info"><b>{translate("Connect")}</b></p>
                <div className="contact-container">
                <p id="contact-info" href="mailto:team@fidweb.net" id="email"><b>E:</b> team@fidweb.net</p>
                <p id="contact-info" id="phone" href="tel:+359 888 999 888"><b>T:</b> +359 2963 1330</p>
                <p id="contact-info" id="adress"><b>A:</b> София, ул. ,,Иван Абаджиев'' 2, сграда А, етаж 4</p>
              </div>
            </div>
            </div>
            <div className="footer-element">
              <div className="footer-inner-element follow">
                <p id="bigger"><b>{translate("Follow")}</b></p>
                <div className="footer-social-media-logo facebook" onClick={() => window.open("https://www.facebook.com/fidwebnet/")}/>
                <div className="footer-social-media-logo instagram" onClick={() => window.open("https://www.instagram.com/fidweb/")}/>
            </div>
          </div>
          </div>
          <div className="lower-footer-container">
          <div className="lower-footer-element">
            <p id="bigger" onClick={() => window.open(Privacy)}>{translate("Privacy")}</p>
            <p id="bigger" onClick={() => window.open(Terms)}>{translate("Usage")}</p>
          </div>
          </div>
          </div>
    }
      </>
            );
};

export default Footer;
