import React from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import data from "../../screens/ArticleInfo/ArticleData.json"
import { translate } from "../../translations/localization";

const Articles={
  "first":"Как се карат шофьорски курсове по време на пандемия",
  "second":"Отнемането на шофьорски книжки",
  "third":"МВР въвежда ускорена yслуга за издаване на шофьорски книжки",
  "fourth":"Шофьорските книжки през годините"
}



export default function NewsComponent()
{  
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const location = useLocation().pathname;

    return (
    <div className="news-container">
        <div className="vertical-news">
        <div className="news-image" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/first-article-photo.png)`}}/>
        <div className="text-container">
            <p className="date-news">12.11.2021</p>
            <h4 className="title-news">{translate("News1")}</h4>
            <div className="line-news-title"/>
            <div className="read-article">
            <div className="read-more" onClick={()=>{
              history.push({
                pathname:"/article",
                search: `/${0}`,
                state: {
                  title:Articles.first,
                  text:data[Articles.first],
                  image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/first-article-photo.png`
                }
            })
            }}>{translate("readmore")}</div>
            </div>
        </div>
      </div>

      <div className="vertical-news line">
        <div className="news-image" id="second" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/second-article-photo.png)`}}/>
        <div className="text-container">
            <p className="date-news">22.10.2021</p>
            <h4 className="title-news">{translate("News2")}</h4>
            <div className="line-news-title"/>
            <div className="read-article">
            <div className="read-more" onClick={()=>{
              history.push({
                pathname:"/article",
                search: `/${1}`,
                state: {
                  title:Articles.second,
                  text:data[Articles.second],
                  image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/second-article-photo.png`
                }
            })
            }}>{translate("readmore")}</div>
            </div>
            </div>
        </div>

    {isMobileVersion === 1 ?  
    
      <div className="vertical-news">  
      <div className="news-image" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/third-article-photo.png)`}}/>
      <div className="text-container">
          <p className="date-news">13.10.2021</p>
          <h4 className="title-news">{translate("News3")}</h4>
          <div className="line-news-title"/>
          <div className="read-more" onClick={()=>{
            history.push({
              pathname:"/article",
              search: `/${2}`,
              state: {
                title:Articles.third,
                text:data[Articles.third],
                image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/third-article-photo.png`
              }
          })
          }}>{translate("readmore")}</div>
          </div>
      </div>
    
    : 
      <div className="vertical-news">    
        <div className="text-container">
            <p className="date-news">13.10.2021</p>
            <h4 className="title-news">{translate("News3")}</h4>
            <div className="line-news-title"/>
            <div className="read-more" onClick={()=>{
              history.push({
                pathname:"/article",
                search: `/${2}`,
                state: {
                  title:Articles.third,
                  text:data[Articles.third],
                  image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/third-article-photo.png`
                }
            })
            }}>{translate("readmore")}</div>
        </div>
        <div className="news-image right" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/third-article-photo.png)`}}/>
      </div>
      
    }


  {isMobileVersion === 1 ? 
    <div className="vertical-news line">
    <div className="news-image" id="second" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/fourth-article-photo.png)`}}/>
        <div className="text-container">
             <p className="date-news">09.09.2021</p>
             <h4 className="title-news">{translate("News4")}</h4>
             <div className="line-news-title"/>
             <div className="read-article">
             <div className="read-more" onClick={()=>{
              history.push({
                pathname:"/article",
                search: `/${3}`,
                state: {
                  title:Articles.fourth,
                  text:data[Articles.fourth],
                  image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/fourth-article-photo.png`
                }
            })
            }}>{translate("readmore")}</div>
             </div>
        </div>
      </div>
  
  :
      <div className="vertical-news line">
        <div className="text-container">
             <p className="date-news">09.09.2021</p>
             <h4 className="title-news">{translate("News4")}</h4>
             <div className="line-news-title"/>
             <div className="read-article">
             <div className="read-more" onClick={()=>{
              history.push({
                pathname:"/article",
                search: `/${3}`,
                state: {
                  title:Articles.fourth,
                  text:data[Articles.fourth],
                  image:`https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/fourth-article-photo.png`
                }
            })
            }}>{translate("readmore")}</div>
             </div>
        </div>
        <div className="news-image right" id="second" style={{backgroundImage:`url(https://digidrive.s3.eu-central-1.amazonaws.com/website-2.0/fourth-article-photo.png)`}}/>
      </div>
  }
    </div>
      )
}