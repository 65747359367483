import React,{useRef} from "react";
import "./styles.scss";
import { Icon } from 'react-icons-kit'
import {ic_arrow_drop_down} from 'react-icons-kit/md/ic_arrow_drop_down';

function Tabs({ tabs, selected, onSelect, wide,icon,black,bigger,right,how }) {
  return (
    <>
    {how === true 
    ? <div className={`tabs-container ${wide && "wide"} ${black && "black"}  ${bigger && "bigger"}`}>
      {tabs.map((tab,i) => {
        return (
            <div style={{display:"flex"}}>
            <div className={`blue-box ${selected === tab  && "selected"}`} id={`blue${i}`}/>
            <div
              className={`tab-element ${selected === tab  && "selected"} ${icon >= 1 && "foricon"} ${how && "how"}`}
              onClick={() => {
                onSelect(tab);
                }}
            >
              <p>{tab}</p>
              <div className = {`arrow-icon ${selected === tab  && "selected"}`}>
                  <Icon size={'50px'} icon={ic_arrow_drop_down}/>
              </div>
              
            </div>
            
          </div>
        );
      })}
    </div> 
    : <div className={`tabs-container ${wide && "wide"} ${black && "black"}  ${bigger && "bigger"}`}>
      {tabs.map((tab,i) => {
        return (
            <div style={{display:"flex",height:`${100/tabs.length}%`}}>
            {how && <div className={`blue-box ${selected === tab  && "selected"}`} id={`blue${i}`}/>}
            <div
              className={`tab-element ${selected === tab  && "selected"} ${icon >= 1 && "foricon"} ${how && "how"}`}
              onClick={() => {
                onSelect(tab);
                }}
            >
              
              {tab === "Map" ? <div className={`icondiv${1} ${right && 'right'}`} /> :
            tab === "ContactForm" ? <div className={`icondiv${2}`} /> :
            
            tab === "Desktop" ? <div className={`icondiv${3}`}/> :
            tab === "Mobile" ? <div className={`icondiv${4}`} /> :
            tab === "Management" ? <div className={`icondiv${3}`}/> :
            tab === "PersonalManagement" ? <div className={`icondiv${4}`}/> :

            tab === "SecondDesktop" ? <div className={`icondiv${5}`}/> :
            tab === "SecondMobile" ? <div className={`icondiv${6}`}/> :
            tab === "Profiles" ? <div className={`icondiv${5}`}/> :
            tab === "PersonalProfile" ? <div className={`icondiv${6}`}/> :
            
            i+1}
              { how && <><p>{tab}</p>
              <div className = {`arrow-icon ${selected === tab  && "selected"}`}>
                  <Icon size={'50px'} icon={ic_arrow_drop_down}/>
              </div>
              </>}
            </div>
            
          </div>
        );
      })}
    </div>}
    </>

  );
}

export default Tabs;
