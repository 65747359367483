import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { contactTypes } from "../actions";
import { ajax } from "rxjs/ajax";
import { contactAPI } from "../config/settings";
import { basicAuth } from "../config/settings";

export const sendEmail = (action$) =>
  action$.pipe(
    ofType(contactTypes.SEND_EMAIL),
    switchMap(({ payload: { system, sender, company, email, phoneNumber, message,onSuccess,onError} }) => {
      return ajax({
        url: `${contactAPI}email`,
        method: "POST",
        headers: basicAuth,
        body: JSON.stringify({ system, sender, company, email, phoneNumber, message }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response)
            onSuccess();
            /*obs.next({
              type: blogTypes.ARTICLES_LIST_SUCCESS,
              payload: response.payload,
            });*/
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            onError(err);
            obs.complete();
          });
        })
      );
    })
  );
