import React,{useState} from "react";
import "./styles.scss";
import FAQ from "./info.json";
import FAQde from "./infode.json";
import { Tabs,SectionForMap,Mapforgoogle } from "../../components";
import {ContactComponent} from "../../components/ContactComponent"
import { Icon } from 'react-icons-kit'
import {ic_arrow_drop_down} from 'react-icons-kit/md/ic_arrow_drop_down'
import mapche from "../../assets/icons/map.svg";
import contactform from "../../assets/icons/contact-form-icon-white.svg";
import { translate } from "../../translations/localization";

const data = FAQ;
const datade=FAQde;

const Options = {
    "First": "ПЪРВИ СТЪПКИ",
    "Credits": "ПРОФИЛ НА ШКОЛА",
    "Graphic": "ПРОФИЛ НА КУРСИСТ",
    "Management": "ОБРАТНА ВРЪЗКА"
}

const OptionsDe = {
  "First": "ERSTE SCHRITTE",
    "Credits": "SCHULPROFIL",
    "Graphic": "STUDENTEN PROFIL",
    "Management": "FEEDBACK"
}

const MapContactTitles={
  "Map": "ГРАФИК И ПРОСЛЕЯВАНЕ",
  "ContactForm" : "Tuk trqbva drugo da stoi!"
}

const HowItWorks = () =>{
    const [type,setType] = useState("Desktop");
    const [selected, setSelected] = useState("ПЪРВИ СТЪПКИ");
    const [selectedDe, setSelectedDe] = useState("ERSTE SCHRITTE");
    const [FunctsTab, setFunctsTab] = useState("ContactForm");
    const [selectedQuestion,setSelectedQuestion]=useState(0);
    const [isClosed,setIsClosed] = useState(false);
    const [GraphicsTab, setGraphicsTab] = useState("ContactForm");
    const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
    const language = localStorage.getItem("immoLanguage");

    return(
        <>
    <div className="Full-container">
    {isMobileVersion === 1 ? <>
      <div className="number1 white"><img src={mapche} /></div> 
      {language === "bg" && <>
      {Object.keys(data).map((key,index)=>{
        return(
        <div className="question-answer-container">
            <div className="questions-container" onClick={()=>{
              if(selectedQuestion === index){
                document.getElementById("question-n-answer").style.display='none';
                setSelectedQuestion(11);
              } 
              else{
                setSelectedQuestion(index)
                document.getElementById("question-n-answer").style.display='block';
              }
            
            }}>
                <div className="mobile-question">{key}</div>
                <div className = {`icon ${selectedQuestion === index && "rotated"}`} id={`icon${index}`}>
                          <Icon size={'40px'} icon={ic_arrow_drop_down}/>
                          </div>
            </div>
            {selectedQuestion === index && <>
              {data[key].map((quesansw,index)=>{
                return(<>
                <div className="inner-questions-n-answers" id="question-n-answer">
                  <ul>
                      <li className="inner-question">{quesansw.Question}</li>
                      <div className="inner-asnwer">{quesansw.Answer}</div>
                      {quesansw.Bulls === undefined ? "" : quesansw.Bulls.map(function (item,i){
                                        return (<>
                                          {i === 0 ?  <li style={{marginTop:"2%"}}>{item}</li> : <li style={{paddingBottom:"3%"}}>{item}</li>}
                                          </>
                                          )
                                      })}
                      </ul>
                </div>
                  
                  </>);

              })}

                
              </>
            }

      </div>);
      })}
      </>
      }
      {language === "de" && <>
      {Object.keys(datade).map((key,index)=>{
        return(
        <div className="question-answer-container">
            <div className="questions-container" onClick={()=>{
              if(selectedQuestion === index){
                document.getElementById("question-n-answer").style.display='none';
                setSelectedQuestion(11);
              } 
              else{
                setSelectedQuestion(index)
                document.getElementById("question-n-answer").style.display='block';
              }
            
            }}>
                <div className="mobile-question">{key}</div>
                <div className = {`icon ${selectedQuestion === index && "rotated"}`} id={`icon${index}`}>
                          <Icon size={'40px'} icon={ic_arrow_drop_down}/>
                          </div>
            </div>
            {selectedQuestion === index && <>
              {datade[key].map((quesansw,index)=>{
                return(<>
                <div className="inner-questions-n-answers" id="question-n-answer">
                  <ul>
                      <li className="inner-question">{quesansw.Question}</li>
                      <div className="inner-asnwer">{quesansw.Answer}</div>
                      {quesansw.Bulls === undefined ? "" : quesansw.Bulls.map(function (item,i){
                                        return (<>
                                          {i === 0 ?  <li style={{marginTop:"2%"}}>{item}</li> : <li style={{paddingBottom:"3%"}}>{item}</li>}
                                          </>
                                          )
                                      })}
                      </ul>
                </div>
                  
                  </>);

              })}

                
              </>
            }

      </div>);
      })}
      </>}
      </>
    
    : 
        <>
              <div className="Left-container">
              {language === "bg" && <Tabs
                          howitworks
                          black
                          wide
                          how
                          selected={selected}
                          tabs={[
                                  Options.First,
                                  Options.Credits,
                                  Options.Graphic,
                                  Options.Management,
                              ]}
                          onSelect={(tab) => setSelected(tab)}
              />}
              {language === "de" && <Tabs
                          howitworks
                          black
                          wide
                          how
                          selected={selectedDe}
                          tabs={[
                                  OptionsDe.First,
                                  OptionsDe.Credits,
                                  OptionsDe.Graphic,
                                  OptionsDe.Management,
                              ]}
                          onSelect={(tab) => setSelectedDe(tab)}
              />}
              </div>
              <div className="Right-container">
              <div className="Questions">
                  <ul>
                  {language === "bg" && <>{data[selected].map((select,index)=>{
                            return(<li style={{borderBottom:"0.5px solid #0BD8DE"}}>
                              <div className="question-and-icon" onClick={()=>{setSelectedQuestion(index)}}>
                              <div className="question"
                                  onClick={()=>{
                                    
                                    if(selectedQuestion === index && isClosed === false){
                                      document.getElementById("answer1").style.display="none";
                                      document.getElementById("Short-demo").style.display="none";
                                      setIsClosed(true);
                                      document.getElementById(`icon${index}`).style.transform = 'rotate('+'0'+'deg)';
                                    }
                                    else if(selectedQuestion === index && isClosed === true){
                                      document.getElementById("answer1").style.display="block";
                                      document.getElementById("Short-demo").style.display="block";
                                      setIsClosed(false);
                                      document.getElementById(`icon${index}`).style.transform = 'rotate('+'180'+'deg)';
                                    }
                                    else {
                                      setSelectedQuestion(index);
                                      setIsClosed(false);
                                    }
                                  }}
                                  id="question">{data[selected][index].Question}
                                  </div>
                                  <div className = {`icon ${selectedQuestion === index && "rotated"}`} id={`icon${index}`}>
                                  <Icon size={'50px'} icon={ic_arrow_drop_down}/>
                                  </div>
                                  </div>
                                  {selectedQuestion === index && <>
                                      <div className={`answer`} id="answer1">{data[selected][index].Answer}</div>
                                      {data[selected][index].Bulls === undefined ? "" : data[selected][index].Bulls.map(function (item,i){
                                        return (<>
                                          {i === 0 ?  <li style={{marginTop:"2%",paddingBottom:"3%"}}>{item}</li> : <li style={{paddingBottom:"3%"}}>{item}</li>}
                                          </>
                                          )
                                      })}
                                  </>
                              }
                          </li>)
                          
                          })}</>}
                          
                  {language === "de" && <>{datade[selectedDe].map((select,index)=>{
                            return(<li style={{borderBottom:"0.5px solid #0BD8DE"}}>
                              <div className="question-and-icon" onClick={()=>{setSelectedQuestion(index)}}>
                              <div className="question"
                                  onClick={()=>{
                                    
                                    if(selectedQuestion === index && isClosed === false){
                                      document.getElementById("answer1").style.display="none";
                                      document.getElementById("Short-demo").style.display="none";
                                      setIsClosed(true);
                                      document.getElementById(`icon${index}`).style.transform = 'rotate('+'0'+'deg)';
                                    }
                                    else if(selectedQuestion === index && isClosed === true){
                                      document.getElementById("answer1").style.display="block";
                                      document.getElementById("Short-demo").style.display="block";
                                      setIsClosed(false);
                                      document.getElementById(`icon${index}`).style.transform = 'rotate('+'180'+'deg)';
                                    }
                                    else {
                                      setSelectedQuestion(index);
                                      setIsClosed(false);
                                    }
                                  }}
                                  id="question">{datade[selectedDe][index].Question}
                                  </div>
                                  <div className = {`icon ${selectedQuestion === index && "rotated"}`} id={`icon${index}`}>
                                  <Icon size={'50px'} icon={ic_arrow_drop_down}/>
                                  </div>
                                  </div>
                                  {selectedQuestion === index && <>
                                      <div className={`answer`} id="answer1">{datade[selectedDe][index].Answer}</div>
                                      {datade[selectedDe][index].Bulls === undefined ? "" : datade[selectedDe][index].Bulls.map(function (item,i){
                                        return (<>
                                          {i === 0 ?  <li style={{marginTop:"2%",paddingBottom:"3%",fontSize:"18px"}}>{item}</li> : <li style={{paddingBottom:"3%",fontSize:"18px"}}>{item}</li>}
                                          </>
                                          )
                                      })}
                                  </>
                              }
                          </li>)
                          
                          })}</>}
                  </ul>
            </div>
              </div>
          </>
    }

    </div>   
    {isMobileVersion === 1 ? 
      <div className="Mobile-Tabs-Contact">
        <div className="number1 white"><img src={contactform} /></div>
        <ContactComponent mobile={1}/>
        <br/>
        <div className="whole-section">
         <div className="number1 white moveupwards"><img src={mapche}/></div>
         <h4>{MapContactTitles.Map}</h4>
          <div>
           <p>{translate("AdditionalInfo")}</p>
          <p><b className="mobile">{translate("Email")}</b><br/>team@digidrive.bg</p>
          <p><b className="mobile">{translate("Phone")}</b><br/>+359 888 999 888</p>
          <br/>
          <p className="mobile-address"><b className="mobile">{translate("Address")}</b><br/>София, ул. „Иван Абаджиев“ 2, сграда А, етаж 4</p>
          <Mapforgoogle 
        center={{   
          lat: 42.6626879801289, 
          lng: 23.38031102658445
         }}

         mobile={1}/>
         </div>
      </div>
         </div>
      
      :

        <div className="Tabs-Contact">
        {GraphicsTab === "Map" ? 
        <SectionForMap
            title={GraphicsTab === "Map" ? MapContactTitles.Map :
                    ""}
            removeLine={1}
            reversed
            invert
            howit
            isWithInvertedTitle={1}
            renderContent={() => {
              return (
                <>
                  <p>{translate("AdditionalInfo")}</p>
                  <div className="Map-Info">
                  <p><b>{translate("Email")}</b><br/>team@digidrive.bg</p>
                  <p><b>{translate("Phone")}</b><br/>+359 888 999 888</p>
                  </div>
                  <br/>
                  <p><b>{translate("Address")}</b><br/>София, ул. „Иван Абаджиев“ 2, сграда А, етаж 4</p>
                  
                  </>
              );}}

      /> : <ContactComponent home/>}
        
      <Tabs
                bigger
                wide
                icon={1}
                selected={GraphicsTab}
                tabs={[
                    "ContactForm",
                    "Map",
              ]}
                onSelect={(tab) => setGraphicsTab(tab)}
            />
            </div>
            } 
        </>
    );
}

export default HowItWorks;