import React from 'react'
import "./styles.scss";
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import pin from "../../assets/icons/homepage-map-pin-icon-smaller.svg";

const containerStyle = {
  width: '47.5%',
  height: '100%'
};

const MobilecontainerStyle = {
width:'100%',
height:'50vh'
};


function Mapforgoogle({mobile}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
  })

  const [map, setMap] = React.useState(null)

  const [position, setPosition] = React.useState({
     
        lat: 42.66213680511652,
        lng: 23.381257433095175
    });

  const mapRef = React.useRef(null);

  function handleLoad(map) {
    mapRef.current = map;
  }
  
  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  return isLoaded ? (
    
      <GoogleMap
        mapContainerStyle={mobile === 1 ? MobilecontainerStyle : containerStyle}
        zoom={19}
        onLoad={handleLoad}
        onDragEnd={handleCenter}
        center={position}
        id="map"
      >
      <Marker
      icon={ { url:pin} }
      position={{
     
        lat: 42.66213680511652,
        lng: 23.381257433095175
    } }
    />
      </GoogleMap>
  ) : <></>
}

export default React.memo(Mapforgoogle);